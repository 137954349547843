import React from 'react';

import { Box, Text, TextProps } from '@opendoor/bricks-next';
import { Entry } from 'contentful';
import 'swiper/css';
import 'swiper/css/pagination';

import { Awaited, EntryComponent } from 'cms/entries/entries';

import { articlesLoader, IArticleLite } from 'components/articles-v2/topicPage/Articles';

import {
  IArticleFields,
  ILpComponentMulticard,
  ILpComponentMulticardCard,
  ILpComponentMulticardCardFields,
  ILpComponentMulticardFields,
} from 'declarations/contentful';

import CardCarouselV2 from '../shared/CardCarouselV2';
import MulticardCard, {
  getCardArch,
  IMulticardCardDetails,
} from '../shared/Multicard/MulticardCard';
import MulticardList from '../shared/Multicard/MulticardList';
import Container from './shared/Container';
import { getComponentThemeColors, IComponentThemeOptions } from './shared/styles/ComponentTheme';
import { Desc, Eyebrow, Subhead, SubheadSmall } from './shared/Typography';
import { markdownToReactElement } from './shared/utils';

const columns = {
  Two: 2,
  Three: 3,
  Four: 4,
};

const verticalAlignment = {
  Top: 'start',
  Middle: 'center',
  Bottom: 'end',
};

export interface ICardListProps<T> {
  id?: string;
  cards: T[];
  componentTheme: IComponentThemeOptions;
  columnNumber: number;
  isArched?: boolean;
  isAccordion?: boolean;
  minHeight?: ILpComponentMulticardFields['minHeight'];
  cardVerticalAlignment: string;
  renderCard?: (card: T) => React.ReactNode;
}

type TextTokenProps = Omit<TextProps, 'tag' | 'typography'> & {
  children?: string;
  size?: Exclude<TextProps['typography'], number | undefined>;
};

interface TitleWithAccentProps extends TextTokenProps {
  title?: string;
  titleAccent?: string;
  titleSize?: any;
  titleColor?: TextProps['color'];
  titleAccentColor?: TextProps['color'];
  breakTitles?: boolean;
}

export const TitleWithAccent = ({
  title,
  titleAccent,
  titleSize,
  titleColor,
  titleAccentColor,
  breakTitles,
  ...props
}: TitleWithAccentProps) => {
  const typography = {
    typography:
      titleSize === 'Large' ? '$headerLarge' : ('$headerMedium' as TextProps['typography']),
    $largerThanSM: {
      typography: titleSize === 'Large' ? '$displaySmall' : '$headerMedium',
    } as TextProps['$largerThanSM'],
    $largerThanMD: {
      typography: titleSize === 'Large' ? '$displaySmall' : '$headerMedium',
    } as TextProps['$largerThanMD'],
  };

  return (
    <Text
      tag="h2"
      {...typography}
      display="block"
      maxWidth={'16ch' as unknown as number}
      color={titleColor || '$contentPrimary'}
      mb={'$8x'}
      {...props}
      whiteSpace="normal"
    >
      {markdownToReactElement(title || '')}
      {breakTitles && <br />}
      {titleAccent && (
        <Text
          tag="span"
          {...typography}
          color={titleAccentColor || '$contentTertiary'}
          {...props}
          whiteSpace="normal"
        >
          {' '}
          {markdownToReactElement(titleAccent)}
        </Text>
      )}
    </Text>
  );
};

type CardSectionHeaderProps = Pick<
  ILpComponentMulticardFields,
  'eyebrow' | 'title' | 'subhead' | 'desc' | 'titleAccent' | 'titleSize'
>;
export const CardSectionHeader = (fields: CardSectionHeaderProps) => {
  return (
    <Box gap={'$16x'} marginBottom={'$16x'} maxWidth={'$744x'}>
      {fields.eyebrow && <Eyebrow>{fields.eyebrow}</Eyebrow>}
      {fields.title && (
        <TitleWithAccent
          title={fields.title}
          titleAccent={fields.titleAccent}
          titleSize={fields.titleSize}
        />
      )}
      {(fields.subhead || fields.desc) && (
        <Box gap="$4x">
          {fields.subhead &&
            (fields.titleSize === 'Large' ? (
              <Subhead color="$contentPrimary">{fields.subhead}</Subhead>
            ) : (
              <SubheadSmall color="$contentPrimary">{fields.subhead}</SubheadSmall>
            ))}
          {fields.desc && (
            <Desc
              color="$contentPrimary"
              $largerThanMD={{ fontSize: fields.titleSize === 'Large' ? 24 : 'unset' }}
            >
              {fields.desc}
            </Desc>
          )}
        </Box>
      )}
    </Box>
  );
};

const mapLPMulticardToGenericMuticard = (
  card: ILpComponentMulticardCardFields,
  isArched: boolean,
  theme: IComponentThemeOptions,
  limit?: number,
): IMulticardCardDetails => {
  // if an image URL is passed, this should take prescedence over the image asset
  const imageUrl = card.imageUrl ? card.imageUrl : card.image?.fields?.file.url;

  return {
    eyebrow: card.eyebrow,
    title: card.title,
    subhead: card.subhead,
    desc: card.desc,
    imageUrl: imageUrl,
    isArched: getCardArch({ cardStyle: card.cardStyle, isArched }),
    ctaDisplayText: card.ctaDisplayText,
    ctaStyle: card.ctaStyle,
    ctaSheetLabel: card.ctaSheetLabel,
    ctaSheet: card.ctaSheet,
    cardStyle: card.cardStyle,
    analyticsId: card.analyticsId,
    componentTheme: theme,
    redirectUrl: card.url,
    cardsLimit: limit,
  };
};

export const mapArticlesToGenericMulticardDetails = (
  article: IArticleFields | IArticleLite,
  theme: IComponentThemeOptions,
  limit?: number,
  hideArticlePublicationDate?: boolean,
): IMulticardCardDetails => {
  return {
    title: article.articleName,
    subhead:
      !hideArticlePublicationDate && article?.publishDate
        ? new Date(article?.publishDate + 'T00:00:00').toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        : '',
    imageUrl:
      article.openGraph?.fields.image ||
      'https://images.opendoor.com/source/s3/imgdrop-production/4b831192b06849a589afcef62a25757d.jpg?preset=square-2048',
    componentTheme: theme,
    redirectUrl: article.slug,
    cardsLimit: limit,
  };
};

const RenderMulticard = (
  entry: ILpComponentMulticard,
  resolvedData?: Awaited<ReturnType<typeof articlesLoader>>,
) => {
  const { fields } = entry;
  const columnNumber = fields.columns ? columns[fields.columns] : 3;
  const componentTheme = fields.cardBackgroundColor
    ? getComponentThemeColors(fields.cardBackgroundColor)
    : getComponentThemeColors('White 0');
  const isArched = fields.cardStyle === 'Arch';
  const minHeight = fields.minHeight || 'auto';
  const cardVerticalAlignment = fields.cardVerticalAlignment
    ? verticalAlignment[fields.cardVerticalAlignment]
    : verticalAlignment['Top'];

  let cards: Array<ILpComponentMulticardCard | Entry<IArticleLite>> = fields.cards || [];
  let isArticles = false;
  if (cards.length === 0 && resolvedData?.articles) {
    isArticles = true;
    cards = resolvedData?.articles;
  }

  const hideArticlePublicationDate = fields.hidePublicationDate;

  const getCardData = (
    card: ILpComponentMulticardCard | Entry<IArticleLite>,
    limit?: number,
  ): IMulticardCardDetails => {
    const res = isArticles
      ? mapArticlesToGenericMulticardDetails(
          (card as Entry<IArticleLite>).fields,
          componentTheme,
          limit,
          hideArticlePublicationDate,
        )
      : mapLPMulticardToGenericMuticard(
          (card as ILpComponentMulticardCard).fields,
          isArched,
          componentTheme,
          limit,
        );
    return res;
  };

  return (
    <Container>
      {fields.title && <CardSectionHeader {...fields} />}
      {fields.display === 'carousel' && (
        <CardCarouselV2
          id={entry.sys.id}
          cards={cards}
          componentTheme={componentTheme}
          columnNumber={columnNumber}
          minHeight={minHeight}
          isArched={isArched}
          footerLink={fields.carouselLink?.fields.slug}
          footerLinkButtonText={fields.carouselLinkText}
          renderCard={(card, id) => (
            <MulticardCard
              id={id}
              card={getCardData(card, fields.limit)}
              componentTheme={componentTheme}
              isArched={isArched}
              cardVerticalAlignment={cardVerticalAlignment}
              columnNumber={columnNumber}
            />
          )}
        />
      )}
      {fields.display === 'list' && (
        <MulticardList
          cards={cards.map((card) => getCardData(card))}
          columnNumber={columnNumber}
          minHeight={minHeight}
          isArched={isArched}
          componentTheme={componentTheme}
          cardVerticalAlignment={cardVerticalAlignment}
        />
      )}
    </Container>
  );
};

const Multicard: EntryComponent<
  ILpComponentMulticard,
  Awaited<ReturnType<typeof articlesLoader>>
> = {
  render: RenderMulticard,
  loader: articlesLoader,
};

export default Multicard;
