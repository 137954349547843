import { useState } from 'react';

import { Box, Image, Text } from '@opendoor/bricks-next';
import dynamic from 'next/dynamic';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentVideoPlayer } from 'declarations/contentful';

import Container from './shared/Container';

// By passes the hydration error we get when the component loads
const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

const RenderVideoPlayer = (entry: ILpComponentVideoPlayer) => {
  const { url, thumbnail, backgroundColor, playButtonLocation, playButtonText } = entry.fields;

  const thumbnailImage = thumbnail?.fields.file.url;
  const thumbnailAlt = thumbnail?.fields.file.fileName;
  const buttonLocation = playButtonLocation || 'Center';

  const VideoIcon = () => {
    const [playHover, setPlayHover] = useState(false);
    return (
      <Box
        display="flex"
        position="absolute"
        height={'100%'}
        width={'100%'}
        justifyContent={buttonLocation === 'Center' ? 'center' : 'flex-end'}
        alignItems={buttonLocation === 'Center' ? 'center' : 'flex-start'}
      >
        <Box
          margin="$16x"
          padding="$8x"
          borderRadius="$8x"
          backgroundColor={
            backgroundColor === 'White 0' ? '$contentInversePrimary' : '$contentPrimary'
          }
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={'$5x'}
          role="button"
          aria-label="Video player button"
          zIndex={1000}
          cursor="pointer"
          overflow="hidden"
          position="relative"
          onMouseEnter={() => setPlayHover(true)}
          onMouseLeave={() => setPlayHover(false)}
        >
          <Text
            color={backgroundColor === 'White 0' ? '$contentPrimary' : '$contentInversePrimary'}
            typography="$labelMedium"
            tag="span"
            display="flex"
          >
            <svg
              style={{ overflow: 'visible', zIndex: 5 }}
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path d="M21 14.5833L9.33337 22.1667L9.33337 7L21 14.5833Z" fill="currentColor" />
            </svg>
          </Text>
          {playButtonText && (
            <Text
              tag="p"
              typography="$labelMedium"
              $largerThanSM={{ typography: '$labelLarge' }}
              color={backgroundColor === 'White 0' ? '$contentPrimary' : '$contentInversePrimary'}
              zIndex={5}
              paddingRight="$6x"
            >
              {playButtonText}
            </Text>
          )}
          <Box
            position="absolute"
            width={'100%'}
            opacity={playHover ? 1 : 0}
            bottom={0}
            left={0}
            height={playHover ? '100%' : 0}
            key="my-square"
            scale={playHover ? 2 : 0}
            animation="decelerateFast"
            animateOnly={['transform']}
            borderTopLeftRadius="$60x"
            borderTopRightRadius="$60x"
            transformOrigin="center bottom"
            backgroundColor={
              backgroundColor === 'White 0' ? '$backgroundTertiary' : '$backgroundInverseTertiary'
            }
          ></Box>
        </Box>
      </Box>
    );
  };

  return (
    <Container>
      <Box
        backgroundColor="$backgroundInversePrimary"
        position="relative"
        pt="56.25%"
        display="flex"
        justifyContent={buttonLocation === 'Center' ? 'center' : 'flex-start'}
        overflow="hidden"
        borderRadius="$16x"
      >
        <ReactPlayer
          url={url}
          controls
          light={
            <Image
              variant="arch"
              width={'100%'}
              height={'100%'}
              source={{
                uri: thumbnailImage,
                height: 740,
                width: 1024,
              }}
              alt={thumbnailAlt || ''}
              borderRadius="$8x"
            />
          }
          playIcon={<VideoIcon />}
          playsinline
          width="100%"
          height="100%"
          playing
          style={{ position: 'absolute', left: 0, top: 0 }}
        />
      </Box>
    </Container>
  );
};

const VideoPlayer: EntryComponent<ILpComponentVideoPlayer> = {
  render: RenderVideoPlayer,
};

export default VideoPlayer;
